.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 65px;
        line-height: 84px;
        color: #fcd700;
        text-transform: uppercase;
        font-family: "Venera";
        font-weight: 900;
        -webkit-text-stroke: 2px;
        text-stroke: 2px;
        text-align: center;
    }

    .table {
        width: 115%;
        max-height: 60vh;
        margin-top: 15px;
        overflow: auto;
        padding-bottom: 100px;
        -webkit-mask-image: linear-gradient(to top, transparent 0%, black 15%);
        mask-image: linear-gradient(to top, transparent 0%, black 15%);

        .row {
            border-radius: 4px;
            margin-bottom: 2px;
            padding: 7.5px 12px;
            display: grid;
            align-items: center;
            grid-template-columns: 100px 1fr 1fr 50px;
            background: rgba(255, 255, 255, 0.02);

            &:nth-child(odd) {
                background: rgba(255, 255, 255, 0.1);
            }

            &.highlight {
                --cosmos-text-color: #fcd700;
            }
        }
    }
}
