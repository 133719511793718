.close {
    position: absolute;
    z-index: 230599;
    height: 90px;
    width: 135px;
    right: 10px;
    cursor: pointer;
    user-select: none;
    background-size: 72px;
    background-position: center;
    background-repeat: no-repeat;
}

.container {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .reset {
        position: absolute;
        z-index: 20;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .touchpoint {
        position: absolute;
        z-index: 21;
        max-width: 200px;
        cursor: pointer;
        transition: 300ms;

        &.hide {
            opacity: 0;
            pointer-events: none;
        }

        .title {
            position: relative;
            z-index: 21;
            font-size: 14px;
            line-height: 20px;
            color: #fcd700;
            font-family: "Venera";
            font-weight: 900;

            img {
                display: inline-block;
                height: 20px;
            }
        }

        .content {
            position: absolute;
            z-index: 22;
            background: #2a4c97;
            bottom: -5px;
            left: -5px;
            padding: 20px 22px;
            width: 275px;
            transition: 250ms;
            opacity: 0;
            pointer-events: none;

            &.isShowing {
                opacity: 1;
            }

            .closeTouchpoint {
                position: absolute;
                top: 5px;
                right: 10px;
                height: 30px;
            }

            .border {
                position: absolute;
                z-index: 1;
                bottom: -15px;
                width: 100%;
                left: 0;
            }

            p {
                font-family: "Bull Text";
                font-size: 14px;
                line-height: 18px;
                text-transform: unset;

                &:first-of-type {
                    font-family: "Venera";
                    font-size: 17px;
                    line-height: 22px;
                    color: #fcd700;
                    font-weight: 900;
                    max-width: 200px;
                    margin-bottom: 12px;
                }
            }
        }

        .arrow {
            display: block;
            position: relative;
            position: absolute;
        }

        &.frontWing {
            left: 3vw;
            bottom: 4vh;

            .content {
                left: 28vw;
            }

            .open {
                margin-bottom: -6px;
                margin-left: 14px;
            }

            .arrow {
                height: 70px;
                top: -80px;
                left: 62px;
            }
        }

        &.frontSuspension {
            left: 31.5vw;
            bottom: 10vh;

            .content {
                left: -14vw;
                right: unset;
                bottom: 40vh;
                width: 450px;
            }

            .open {
                margin-bottom: -6px;
                margin-left: 14px;
            }

            .arrow {
                height: 120px;
                top: -135px;
                left: 0;
            }
        }

        &.halo {
            left: 42vw;
            top: 30vh;

            .content {
                left: 12vw;
                right: unset;
                bottom: -150px;
            }

            .open {
                margin-bottom: -6px;
                margin-left: 8px;
            }

            .arrow {
                height: 50px;
                bottom: -55px;
                right: 10px;
            }
        }

        &.floor {
            left: 68vw;
            bottom: 24vh;

            .content {
                left: 7vw;
                right: unset;
                bottom: 50px;
            }

            .open {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -23px;
            }

            .arrow {
                height: 50px;
                top: -60px;
                left: 15px;
            }
        }

        &.coolingSidePods {
            left: 62vw;
            top: 23vh;

            .title {
                max-width: 120px;
            }

            .content {
                left: -10vw;
                right: unset;
                bottom: -480px;
                width: 400px;
            }

            .open {
                position: absolute;
                margin-left: 10px;
                bottom: 2px;
            }

            .arrow {
                height: 100px;
                bottom: -110px;
                left: 15px;
            }
        }

        &.rearWing {
            right: -1vw;
            top: 19vh;

            .content {
                left: unset;
                right: 35px;
                bottom: -480px;
                width: 340px;
            }

            .open {
                margin-bottom: -6px;
                margin-left: 14px;
            }

            .arrow {
                height: 70px;
                bottom: -80px;
                left: -2px;
            }
        }

        &.rearSuspension {
            right: 6vw;
            bottom: 50vh;

            .title {
                max-width: 120px;
            }

            .content {
                left: unset;
                right: -20px;
                bottom: -315px;
                width: 475px;
            }

            .open {
                position: absolute;
                margin-left: 117%;
                bottom: 2px;
            }

            .arrow {
                height: 30px;
                bottom: 0;
                right: 110%;
            }
        }
    }
}
