.close {
    position: absolute;
    z-index: 230599;
    height: 90px;
    width: 135px;
    right: 10px;
    cursor: pointer;
    user-select: none;
    background-size: 72px;
    background-position: center;
    background-repeat: no-repeat;
}

.container {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
        width: 85vw;
        text-align: left;
        padding: 0 0 40px 0;
        font-size: 46px;
        line-height: 38px;
        color: #fcd700;
        text-transform: uppercase;
        font-family: "Venera";
        font-weight: 900;
        -webkit-text-stroke: 1px;
        text-stroke: 1px;
        background-size: 60vw;
        background-repeat: no-repeat;
        background-position: left 20px;
    }

    .operations {
        width: 85vw;
    }
}
