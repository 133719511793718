.container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    pointer-events: all;
    transition: 750ms opacity;
    position: fixed;
    z-index: 230599;
    backdrop-filter: blur(25px);

    &.isHidden {
        opacity: 0;
        pointer-events: none;
    }

    cosmos-spinner {
        margin-left: 8px;
    }
}
