.container {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;

    h1 {
        display: flex;
        align-items: center;
        font-size: 46px;
        line-height: 38px;
        color: #fcd700;
        text-transform: uppercase;
        font-family: "Venera";
        font-weight: 900;
        -webkit-text-stroke: 1px;
        text-stroke: 1px;
        text-align: center;
        padding: 60px 50px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center 30px;
        margin-bottom: -22px;

        span {
            color: #a7aaac;
            font-family: "Share Tech Mono";
            line-height: 26px;
            font-size: 26px;
            letter-spacing: -2.5px;
            margin-top: -4px;
            margin-left: 14px;
            -webkit-text-stroke: 0.25px;
            text-stroke: 0.25px;
        }
    }
}

.grid {
    display: grid;
    grid-gap: 40px;
    grid-template-rows: 126px;
    position: relative;
    z-index: 100;
    width: 75vw;
    aspect-ratio: 1640 / 855;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .row {
        display: grid;
        grid-template-columns: 86px 86px calc(87.5px * 4) calc(87.5px * 4);

        .date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;

            span {
                display: block;
                color: #a7aaac;
                font-family: "Share Tech Mono";
                font-size: 36px;
                line-height: 36px;
                text-transform: uppercase;
                letter-spacing: -2.5px;
                text-align: left;
                width: 54px;
            }
        }

        .session {
            position: relative;
            display: grid;
            grid-gap: 12px;
            grid-template-columns: 11px 1fr;
            align-items: center;
            padding-left: 14px;

            &.isRace {
                grid-template-columns: 50px 1fr;
            }

            img {
                position: relative;
                z-index: 2;
                width: 100%;
            }

            .raceCircle {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 40%;
                transform: translate(-50%, -50%);
            }

            span {
                position: relative;
                display: block;
                z-index: 2;

                &:first-of-type {
                    font-family: "Venera";
                    font-weight: 900;
                    text-transform: uppercase;
                    font-size: 24px;
                    line-height: 23px;
                    color: #fcd700;
                    letter-spacing: -1px;
                    margin-bottom: 4px;
                }

                &:last-of-type {
                    font-family: "Share Tech Mono";
                    font-weight: 900;
                    text-transform: uppercase;
                    font-size: 36px;
                    line-height: 33px;
                    color: #fff;
                    letter-spacing: -3.5px;
                    margin-bottom: -4px;
                }
            }
        }
    }

    .logo {
        position: absolute;
        z-index: 400;
        height: 90px;
        right: -40px;
        bottom: -22px;
    }
}
