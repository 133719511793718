.close {
    position: absolute;
    z-index: 230599;
    height: 90px;
    width: 135px;
    right: 10px;
    cursor: pointer;
    user-select: none;
    background-size: 72px;
    background-position: center;
    background-repeat: no-repeat;
}

.container {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
        position: relative;
        z-index: 10;
        width: 80vw;
        text-align: right;
        padding: 0 0 40px 0;
        font-size: 46px;
        line-height: 38px;
        color: #fcd700;
        text-transform: uppercase;
        font-family: "Venera";
        font-weight: 900;
        -webkit-text-stroke: 1px;
        text-stroke: 1px;
        background-size: 41vw;
        background-repeat: no-repeat;
        background-position: right 30px;
        margin-bottom: -35px;
    }

    .operations {
        width: 79vw;
    }
}

.modal {
    position: fixed;
    z-index: 999;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    pointer-events: none;
    backdrop-filter: blur(0px);
    transition: 250ms all;
    display: flex;
    align-items: center;
    justify-content: center;

    &.isToggled {
        backdrop-filter: blur(10px);
        pointer-events: all;

        .card {
            opacity: 1;
            top: 0;
        }
    }

    .card {
        position: relative;
        top: 40px;
        opacity: 0;
        transition: 200ms all;
    }

    .graphic {
        max-width: 750px;
    }

    .sticker {
        position: absolute;
        z-index: 202;
        bottom: -28px;
        left: -24px;
        height: 80px;
    }

    .grid {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr;

        .imageContainer {
            background-size: 88%;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;

            .pictureProfile {
                height: 300px;
                aspect-ratio: 1/1;
                width: 90%;
                margin: auto;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                .profilePictureInner {
                    width: 80%;
                    aspect-ratio: 1/1;
                    background: #f7f7f7;
                    margin: 24px auto 0;
                    transform: rotate(-1.75deg);
                    background-size: cover;
                    background-position: center;
                }
            }
        }

        .textContainer {
            margin: 40px 30px 0 0;

            .name {
                display: flex;
                align-items: center;
                font-size: 35px;
                line-height: 38px;
                color: #fcd700;
                text-transform: uppercase;
                font-family: "Venera";
                font-weight: 900;
                -webkit-text-stroke: 1px;
                text-stroke: 1px;
                min-height: 135px;
                background-size: contain;
                background-position: center left;
                background-repeat: no-repeat;
            }

            .bio {
                margin-top: 8px;

                p {
                    font-size: 14.5px;
                    line-height: 1.2em;
                    color: #fcd700;
                    text-transform: uppercase;
                    font-family: "Venera";

                    &:not(:first-of-type) {
                        margin-top: 19px;
                    }

                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.personToggles {
    position: absolute;
    z-index: 101;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    .toggle {
        position: absolute;
        height: 27px;
        width: 30px;
        background-size: 70%;
        background-position: center;
        background-repeat: no-repeat;
        padding: 24px;
        transform: translate(-30px, -30px);
    }
}
