.location {
    position: absolute;
    transform-origin: top left;
    transform: rotate(-90deg);
    left: 35px;
    bottom: 0;

    p {
        font-family: "Share Tech Mono";
        text-transform: uppercase;
        color: #fcd700;

        &:nth-child(1) {
            font-size: 34px;
            margin-bottom: 5px;
        }

        &:nth-child(2) {
            font-size: 19px;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 96px;
        line-height: 84px;
        color: #fcd700;
        text-transform: uppercase;
        font-family: "Venera";
        font-weight: 900;
        -webkit-text-stroke: 4px;
        text-stroke: 4px;
        text-align: center;
    }

    .track {
        width: 60vw;
        margin: 20px auto 40px;

        img {
            position: relative;
            max-height: 54vh;
            margin: auto;
        }
    }

    .stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 62vw;

        .stat {
            img {
                height: 19px;
            }

            span {
                display: block;
                margin-top: 5px;
                font-family: "Share Tech Mono";
                letter-spacing: -1.5px;
                font-size: 50px;
            }
        }
    }
}
