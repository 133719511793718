.container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        user-select: none;
        height: 100%;
        width: 100%;

        &:nth-child(1) {
            z-index: 104;
            opacity: 0.27;
            mix-blend-mode: overlay;
        }

        &:nth-child(2) {
            z-index: 103;
            opacity: 0.54;
            mix-blend-mode: hard-light;
        }

        &:nth-child(3) {
            z-index: 102;
            mix-blend-mode: color-dodge;
        }

        &:nth-child(4) {
            z-index: 101;
            height: 100vh;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
        }

        &:nth-child(5) {
            z-index: 100;
            height: auto;
            width: 100vw;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .cards {
        position: relative;
        z-index: 1;
        display: grid;
        grid-gap: 0;
        grid-template-columns: repeat(2, 1fr);

        .card {
            height: 50vh;
            background-size: cover;
            background-position: center;
            cursor: pointer;

            &.isDisabled {
                pointer-events: none;
                opacity: 0.25;
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;

                .title {
                    width: 100%;
                    padding: 25px 0;
                    text-align: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    h1 {
                        font-size: 60px;
                        line-height: 65px;
                        color: #fcd700;
                        text-transform: uppercase;
                        font-family: "Venera";
                        font-weight: 900;
                        -webkit-text-stroke: 4px;
                        text-stroke: 4px;
                        margin: 0;
                    }
                }

                .button {
                    text-transform: uppercase;
                    font-family: "Venera";
                    background: #fcd700;
                    color: #001a30;
                    font-weight: 900;
                    margin-top: 28px;
                    padding: 5px 10px;
                    border-radius: 4px;
                }
            }
        }
    }
}
